<template>
  <el-dialog
    :title="orgName"
    v-model="dialogVisible"
    width="1200px"
    :before-close="handleClose"
  >
    <div
      id="org"
      style="width: 100%"
      :style="{ height: contentStyleObj }"
    ></div>
  </el-dialog>
</template>

<script type="text/javascript">
import { zxOrgStat } from "@/api/org";
import * as echarts from "echarts";
let resizeFunc;
export default {
  name: "org",
  data() {
    return {
      contentStyleObj: {},
      dialogVisible: false,
      orgName: "",
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(320);
  },
  beforeUnmount() {
    window.removeEventListener("resize", resizeFunc);
  },
  methods: {
    init(row) {
      this.orgName = row.orgName;
      zxOrgStat({ orgId: row.orgId }).then((res) => {
        if (res.data.msg == "success") {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.initChart(res.data.data.list);
          });
        }
      });
    },
    getResizeFunc(chartInstance) {
      resizeFunc = () => {
        chartInstance.resize();
      };
    },
    initChart(list) {
      var myChart = echarts.init(document.getElementById("org"));
      let timeInit = list.map((v) => {
        return v.statTime;
      });
      let userData = list.map((v) => {
        return v.userCount;
      });
      let keepData = list.map((v) => {
        return v.keepCount;
      });
      let periodData = list.map((v) => {
        return v.periodCount;
      });
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["用户数", "记账客户数", "本期使用数"],
          x: "left",
        },
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeInit,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "用户数",
            type: "line",
            data: userData,
          },
          {
            name: "记账客户数",
            type: "line",
            data: keepData,
          },
          {
            name: "本期使用数",
            type: "line",
            data: periodData,
          },
        ],
      });
      this.getResizeFunc(myChart);
      window.addEventListener("resize", resizeFunc);
    },
  },
};
</script>

<style></style>
