<template>
  <el-drawer
    title="统计"
    v-model="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    size="70%"
  >
    <div style="margin-bottom: 10px">
      <el-button
        icon="RefreshRight"
        size="small"
        @click="getList()"
      ></el-button>
      <el-input
        size="small"
        placeholder="请输入代账公司名称"
        v-model.trim="listQuery.orgName"
        style="width: 160px; margin: 0 5px"
        @keyup.enter="getList"
        clearable
      ></el-input>
      <el-button size="small" type="primary" @click="getList">
        <el-icon><Search /></el-icon><span> 搜索</span>
      </el-button>
      <el-select
        placeholder="请选择集团"
        v-model="listQuery.groupId"
        size="small"
        filterable
        clearable
        @change="getList"
        v-if="!props.from"
        style="margin-right: 0;"
      >
        <el-option
          v-for="item in groupOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        style="width: 130px;margin-left:5px"
        size="small"
        value-format="YYYY-MM-DD"
        v-model="listQuery.date"
        type="date"
        placeholder="请选择时间"
        @change="getList"
        :clearable="false"
      >
      </el-date-picker>
    </div>
    <el-table
      :data="showList"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
      show-summary
      :summary-method="getSummaries"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="groupName" label="集团名称" min-width="150">
      </el-table-column>
      <el-table-column
        prop="orgName"
        label="公司名"
        min-width="200"
        align="left"
      >
        <template #default="scope">
          <span @click="checkOrg(scope.row)" style="cursor: pointer">
            <el-icon style="color: #17a2b8"><Tickets /></el-icon>
            {{ scope.row.orgName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="statTime"
        label="日期"
        width="100"
        align="center"
      />
      <el-table-column
        prop="userCount"
        label="用户数"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="keepCount"
        label="记账客户数"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="本期使用数"
        width="100"
        align="center"
        prop="periodCount"
      >
      </el-table-column>
      <el-table-column
        prop="maxUserCount"
        label="最大用户数"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="maxKeepCount"
        label="最大记账客户数"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="maxPeriodCount"
        label="最大本期使用数"
        width="120"
        align="center"
      >
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="changeLimit"
      />
    </div>
  </el-drawer>
  <orgStatTotal ref="OrgStatTotal" />
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { groupList, orgStatList, groupOrgStatList } from "@/api/org";
import orgStatTotal from "./orgStatTotal.vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
});
const listQuery = ref({
  page: 1,
  limit: 20,
  date: "",
});
const drawer = ref(false);
const list = ref([]);
const showList = ref([]);
const total = ref(0);
const loading = ref(false);
const contentStyleObj = ref({});
const sumInfo = ref({
  sumKeepCount: 0, //合计记账户数
  sumMaxKeepCount: 0, //合计最大记账户数
  sumMaxPeriodCount: 0, //合计最大本期使用数
  sumMaxUserCount: 0, //合计最大用户数
  sumPeriodCount: 0, //合计本期使用数
  sumUserCount: 0, //合计用户数
});
const groupOption = ref([]);
const OrgStatTotal = ref();
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  getDay();
  if(!props.from){
    getGroupList();
  }
});
const getDay = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day <= 9 ? "0" + day : day;
  listQuery.value.date = year + "-" + month + "-" + day;
};
const getGroupList = () => {
  groupList({}).then((res) => {
    if (res.data.msg == "success") {
      groupOption.value = res.data.data.list;
    }
  });
};
const init = () => {
  drawer.value = true;
  if (props.from) {
    getListG();
  } else {
    getList();
  }
};
const getList = () => {
  listQuery.value.groupId = listQuery.value.groupId
    ? listQuery.value.groupId
    : null;
  loading.value = true;
  orgStatList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      sumInfo.value.sumUserCount = res.data.data.sumUserCount;
      sumInfo.value.sumKeepCount = res.data.data.sumKeepCount;
      sumInfo.value.sumMaxUserCount = res.data.data.sumMaxUserCount;
      sumInfo.value.sumMaxKeepCount = res.data.data.sumMaxKeepCount;
      sumInfo.value.sumMaxPeriodCount = res.data.data.sumMaxPeriodCount;
      sumInfo.value.sumPeriodCount = res.data.data.sumPeriodCount;
      total.value = res.data.data.total;
      showList.value = proxy.$qzfCopy(list.value);
      changeLimit();
    }
  });
};
const getListG = () => {
  loading.value = true;
  groupOrgStatList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      sumInfo.value.sumUserCount = res.data.data.sumUserCount;
      sumInfo.value.sumKeepCount = res.data.data.sumKeepCount;
      sumInfo.value.sumMaxUserCount = res.data.data.sumMaxUserCount;
      sumInfo.value.sumMaxKeepCount = res.data.data.sumMaxKeepCount;
      sumInfo.value.sumMaxPeriodCount = res.data.data.sumMaxPeriodCount;
      sumInfo.value.sumPeriodCount = res.data.data.sumPeriodCount;
      total.value = res.data.data.total;
      showList.value = proxy.$qzfCopy(list.value);
      changeLimit();
    }
  });
};
const changeLimit = () => {
  let arr = list.value;
  showList.value = arr
    ? arr.slice(
        (listQuery.value.page - 1) * listQuery.value.limit,
        listQuery.value.page * listQuery.value.limit
      )
    : [];
};

const getSummaries = (param) => {
  let { columns, data } = param;
  data = sumInfo.value;
  let userCount = 0;
  let keepCount = 0;
  let periodCount = 0;
  let maxUserCount = 0;
  let maxKeepCount = 0;
  let maxPeriodCount = 0;
  userCount = data.sumUserCount;
  keepCount = data.sumKeepCount;
  periodCount = data.sumPeriodCount;
  maxUserCount = data.sumMaxUserCount;
  maxKeepCount = data.sumMaxKeepCount;
  maxPeriodCount = data.sumMaxPeriodCount;
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = "合计";
      return;
    }
    if (column.property == "userCount") {
      sums[index] = userCount;
    } else if (column.property == "keepCount") {
      sums[index] = keepCount;
    } else if (column.property == "periodCount") {
      sums[index] = periodCount;
    } else if (column.property == "maxUserCount") {
      sums[index] = maxUserCount;
    } else if (column.property == "maxKeepCount") {
      sums[index] = maxKeepCount;
    } else if (column.property == "maxPeriodCount") {
      sums[index] = maxPeriodCount;
    } else {
      sums[index] = "";
    }
  });
  return sums;
};
const checkOrg = (row) => {
  OrgStatTotal.value.init(row);
};
defineExpose({
  init,
});
</script>
